<template>
    <div class="opciones_upper">
        <b-container class="opciones_container overlay primario">
            <b-row class="opciones_wrap" align-v="center">
                <b-col lg="4" md="12" sm="12" v-for="(opcion, index) in data" :key="index"  class="opcion_wrap">
                    <b-img v-if="opcion.icon" :src="require('@/assets/img/'+opcion.icon)" fluid alt="Responsive image"></b-img>
                    <h3 v-if="opcion.title">{{opcion.title}}</h3>
                    <p v-if="opcion.description">{{opcion.description}}</p>
                    <a v-if="opcion.more" class="mas-arrow" href="">{{opcion.more}}</a>
                    <a v-else class="mas-arrow mas-arrow_mobile" href="">más información</a>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import router from '../../router'
export default {
    data() {
        return {
            router,
        }
    },
    props:{
        data: Array,
    }
}
</script>

<style scoped>
.opciones_container .opciones_wrap{
    margin-top: -120px;
}
.opciones_container .opciones_wrap .opcion_wrap{
    padding: 8px 80px;
    margin: 20px 0;
}
.opciones_container .opciones_wrap .opcion_wrap:not(:last-child){
    border-right: 2px dashed var(--blanco);
}
.opciones_container .opcion_wrap img{
    display: block;
    margin: 0 auto 20px;
}
.opciones_container .opciones_wrap h3{
    font-family: 'Texta';
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    line-height: 0.95;
    color: var(--blanco);
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
}
.opciones_container .opciones_wrap p{
    font-family: 'Texta';
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    line-height: 0.83;
    color: var(--blanco);
    text-align: center;
    margin-bottom: 24px;
}
.opciones_container .opciones_wrap a{
    font-size: 10px;
    font-weight: normal;
    font-style: italic;
    line-height: 1;
    color: var(--blanco);
    display: block;
    text-align: center;
}
@media(max-width: 992px){
    .opciones_container .opciones_wrap h3{
        font-size: 19px;
    }
    .opciones_container .opciones_wrap .opcion_wrap{
        padding: 31px 27%;
    }
    .opciones_container .opciones_wrap{
        margin-top: -100px;
    }
}
@media(max-width: 576px){
    .opciones_container .opciones_wrap{
        margin-top: -80px;
    }
    .opciones_container .opciones_wrap .opcion_wrap{
        padding: 31px 16%;
        margin: 0 10%;
    }
    .opciones_container .opciones_wrap p{
        font-size: 14px;
        line-height: 1.1;
    }
    .opciones_container .opciones_wrap .opcion_wrap:not(:last-child){
        border-right: none;
        border-bottom: 2px dashed var(--blanco);
    }
}
</style>