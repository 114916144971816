<template>
  <div class="service_section">
    <Banner :data="bannerData" />
    <RowRed :data="redData"/>
    <ImageOverlap id="gestion" :data="overData" />
    <ProyectosTabsComercial />
    <ImageOverlap :data="overData1" />
    <Contacto />
  </div>
</template>


<script>
import Banner from "@/components/shared/Banner.vue";
import RowRed from "@/components/shared/RowRed.vue";
import ImageOverlap from "@/components/shared/ImageOverlap.vue";
import ProyectosTabsComercial from "@/components/shared/ProyectosTabsComercial.vue";
import Contacto from "@/components/shared/Contacto.vue";
export default {
  components: {
    Banner,
    RowRed,
    ImageOverlap,
    ProyectosTabsComercial,
    Contacto,
  },
  data(){
    return{
        
    }
  },
  computed:{
    //Cambiar aqui los textos dinamicos para que se rendereen en español e ingles
    bannerData(){
      return {
        bg: 'img/Miami.jpg',
        title: this.$t('us.banner.title'),
      }
    },
    redData(){
      var Arrservices = Array();
      Arrservices.push({
                icon: 'svg/ico-gestion.svg',
                title: this.$t('home.redCTA.gestion.title')
            })
      Arrservices.push({
                icon: 'svg/ico-comercializacion.svg',
                title: this.$t('home.redCTA.comer.title')
            })
      Arrservices.push({
                icon: 'svg/ico-consultoria.svg',
                title: this.$t('home.redCTA.consul.title')
            })
      return Arrservices;
    },
    overData(){
      return {
        image: 'img/gestion.jpg',
        icon: 'img/svg/gestion-color.svg',
        title: this.$t('services.title'),
        description: this.$t('services.desc')
      }
    },
    overData1(){
      return {
        inverted: true,
        image: 'img/consultoria.jpg',
        icon: 'img/svg/consultoria-color.svg',
        title: this.$t('services.title1'),
        description: this.$t('services.desc1')
      }
    }
  }
};
</script>

<style scoped>
.service_section >>> .opciones_container .opciones_wrap{
  margin-top: -80px;
}
</style>