<template>
    <div class="imageO_upper">
        <b-container class="imageO_container">
            <b-row no-gutters class="imageO_wrap" :class="data.inverted?'inverted':''" align-v="center">
                <b-col lg="6" class="image_wrap ">
                    <b-img v-if="data.image" :src="require('@/assets/'+data.image)" fluid alt="Responsive image"></b-img>
                </b-col>
                <b-col lg="6" class="image_wrap icon content">
                    <div class="title-img">
                        <b-img v-if="data.icon" :src="require('@/assets/'+data.icon)" fluid alt="Responsive image"></b-img>
                        <h2 v-if="data.title" v-html="data.title"></h2>
                    </div>
                    <p v-if="data.description">
                        <span v-html="data.description.slice(0, 450)"></span>
                        <span v-if="!readMore">...</span>
                        <span v-if="data.description.length>450 && readMore" v-html="data.description.slice(450,-1)"></span>
                    </p>
                    <button @click="readMore =! readMore" class="read_more">
                        <span v-if="!readMore">Read more</span>
                        <span v-else>Read less</span>
                    </button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    props:{
        data: Object,
    },
    data(){
        return {
            readMore: false,
            readMoreActive: false
        }
    }
}
</script>

<style scoped>
.read_more{
    cursor: pointer;
    border: none;
    background: transparent;
    color: var(--primario);
}
.imageO_container .imageO_wrap.inverted{
    flex-direction: row-reverse;
}
.imageO_container .imageO_wrap .content{
    padding: 2rem 4rem;
    background: var(--gris);
    margin-left: -5rem;
}
.imageO_container .imageO_wrap.inverted .content{
    margin-left: 0;
    margin-right: -5rem;
}
.imageO_container .imageO_wrap .title-img{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.imageO_container .imageO_wrap.inverted  .title-img{
    justify-content: flex-start;
}
.imageO_container .imageO_wrap h2{
    font-size: 35.9px;
    font-weight: 900;
    line-height: 0.8;
    letter-spacing: -0.14px;
    color: var(--secundario);
}
.imageO_container .imageO_wrap h2 >>> span{
    display: block;
    font-size: 46.7px;
    font-weight: 300;
    letter-spacing: -0.19px;
}
.imageO_container .imageO_wrap p{
    font-size: 20px;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: 0.94px;
    color: var(--texto);
}
.imageO_container .imageO_wrap img{
    padding: 52px 0;
}
@media(max-width: 992px){
    .imageO_container{
        max-width: 100%;
        padding: 0;
    }
    .imageO_container .imageO_wrap:not(.inverted) img{
        padding-bottom: 0;
    }
    .imageO_container .imageO_wrap img{
        padding: 20px 0;
    }
    .imageO_container .imageO_wrap.inverted .image_wrap:not(.icon) img{
        padding-top: 0;
    }
    .imageO_container .imageO_wrap .content{
        margin-left: 0;
    }
    .imageO_container .imageO_wrap.inverted{
        flex-direction: column-reverse;
    }
    .imageO_container .imageO_wrap.inverted .content{
        margin-right: 0;
    }
}
@media(max-width: 576px){
    .imageO_container .imageO_wrap:not(.inverted) img{
        padding-top: 0;
    }
    .imageO_container .imageO_wrap .content{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .imageO_container .imageO_wrap p{
        font-size: 16px;
        line-height: 1.1;
        text-align: center;
    }
}
</style>