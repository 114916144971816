<template>
    <div class="banner_upper overlay"
    :style="{ backgroundImage: `url(${require('@/assets/'+data.bg)})` }">
        <b-container fluid class="banner_container" >
            <b-row class="banner_wrap" align-v="center">
                <b-col lg="12" md="7" sm="6">
                    <h1 v-html="data.title"></h1>

                    <b-link v-if="data.btn" @click="router.push(data.link)">
                        {{data.btn}} 
                        <span><b-img :src="require('@/assets/img/svg/ico-plus.svg')"></b-img></span>
                    </b-link>

                </b-col>
                <p v-if="data.scrollDown" @click="scrollingDown()" class="text-center scroll-down" v-html="data.scrollText"></p>
                <b-img v-if="data.scrollDown" @click="scrollingDown()" class="scroll-down_img" :src="require('@/assets/img/svg/scroll-down.svg')"></b-img>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import router from '../../router'
export default {
    data() {
        return {
            router,
        }
    },
    props:{
        data: Object,
    },
    methods:{
        scrollingDown(){
            window.scroll({
                top: 500,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style scoped>
.banner_upper{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.banner_container .banner_wrap{
    min-height: 750px;
}
.banner_container .banner_wrap h1{
    width: max-content;
    font-size: 44px;
    line-height: 0.93;
    font-weight: 300;
    color: var( --blanco );
    padding-bottom: 5px;
    border-bottom: 4px solid var( --primario );
    margin-bottom: 40px;
}
.banner_container .banner_wrap h1 >>> b{
    font-size: 53.9px;
}
.banner_container .banner_wrap a{
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.2;
    color: var(--blanco);
    background: linear-gradient(90deg, var(--primario) 48%, var(--transparente) 48%);
    padding: 2px 4px;
}
.banner_container .banner_wrap .scroll-down{
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    line-height: 0.2;
    color: var(--blanco);
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    cursor: pointer;
}
.banner_container .banner_wrap .scroll-down >>> b{
    font-size: 14.1px;
    font-weight: 900;
    line-height: 3.33;
    color: var(--blanco);
}

.banner_container .banner_wrap .scroll-down_img{
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    cursor: pointer;
}
@media(max-width:992px){
    .banner_container .banner_wrap h1{
        font-size: 40px;
        width: auto;
    }
    .banner_container .banner_wrap{
        min-height: 600px;
    }
}
@media(max-width:576px){
    .banner_container .banner_wrap h1{
        font-size: 27px;
        border-bottom-width: 2.4px;
    }
    .banner_container .banner_wrap .scroll-down{
        line-height: 1;
    }
    .banner_container .banner_wrap .scroll-down_img{
        filter: brightness(0) invert(1);
    }
    .banner_container .banner_wrap h1 >>> b{
        font-size: 34px;
    }
    .banner_container .banner_wrap{
        min-height: 480px;
    }
    .banner_container .banner_wrap a{
        width: 100%;
        display: block;
        background: transparent;
        text-align: center;
    }
    .banner_container .banner_wrap a span{
        width: 100%;
        display: block;
        margin: -8px 0;
    }
    .banner_container .banner_wrap a img{
        filter: brightness(0) invert(1);
    }
}
</style>