<template>
  <div class="proyectos_upper">
    <b-container class="proyectos_container">
      <b-row
        no-gutters
        class="proyectos_wrap"
        align-h="between"
        align-v="center"
      >
        <b-col lg="6" class="proyecto_wrap">
          <h2 v-html="this.$t('know.projects')"></h2>
        </b-col>
        <b-col lg="6" class="proyecto_wrap">
          <hr />
        </b-col>
      </b-row>
      <b-row no-gutters class="proyectos_wrap overlap-top" align-v="center">
        <b-col lg="6" class="proyecto_wrap">
          <h3 class="overlay primario">
            <span v-html="this.$t('projects.category.Comercialización')"></span>
            <b-img
              :src="require('@/assets/img/svg/ico-comercializacion.svg')"
              fluid
              alt="Responsive image"
            ></b-img>
          </h3>
        </b-col>
      </b-row>
      <b-row no-gutters class="proyectos_wrap tabs" align-h="between">
        <b-col lg="12" class="proyecto_wrap">
          <b-tabs content-class="" align="right">
            <b-tab
              v-for="categoria in categorias"
              @click="categoriaActiva = categoria.id"
              :key="categoria.id"
              :title="localeCode==='es' ? categoria.nombre : categoria.nombreIngles "
              :active="categoriaActiva === categoria.id"
            >
              <VueSlickCarousel
                v-bind="settings"
                v-if="proyectosFiltrados && proyectosFiltrados.length > 0"
              >
                <CarouselPosts
                  v-for="proyecto in proyectosFiltrados"
                  :key="proyecto.id"
                  :proyecto="proyecto"
                  tipo="proyecto"
                />
              </VueSlickCarousel>
              <p v-else class="mt-5">No hay proyectos en esta categoria.</p>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <b-link class="mas-arrow big" @click="router.push('proyectos')"></b-link>
    </b-container>
    <b-container fluid class="full proyectosD_container" v-if="proyectoActivo">
      <b-row
        no-gutters
        class="proyectosD_wrap"
        align-h="between"
        align-v="stretch"
      >
        <b-col lg="9" class="proyectoD_wrap image_wrap">
          <b-carousel
            id="carousel-1"
            :interval="4000"
            controls
            img-height="480"
          >
            <b-carousel-slide
              v-for="(galeria, index) in proyectoActivo.galeria"
              :key="index"
              :img-src="proyectoActivo.galeria[index].url"
            >
            </b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col lg="3" class="proyectoD_wrap content_wrap">
          <div class="proyectoD_desc">
            <h3>
              {{ proyectoActivoNombre }} <br />
              {{ proyectoActivo.direccion.toUpperCase() }}
            </h3>
            <p v-html=" localeCode==='es' ? proyectoActivo.descripcion : proyectoActivo.descripcionIngles " />
          </div>
        </b-col>
        <div style="width: 100%; text-align:center;" v-if="proyectoActivo.pdf">
          <b-button v-b-modal.modal-1 class="b-modal_book mt-3">
            <b-img :src="require('@/assets/img/svg/ico-download.svg')" class="mr-2"></b-img>
            {{this.$t('common.download_book')}}.
          </b-button>
          <b-modal id="modal-1" centered :hide-footer="true" :hide-header="true">
            <b-form @submit.prevent="sendInfo()">
              <b-form-group id="input-group-1" label="Nombre" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.nombre"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2" label="E-mail" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.correo"
                  type="email"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Teléfono"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.telefono"
                  type="tel"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Mensaje"
                label-for="textarea"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="form.mensaje"
                  placeholder=""
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button type="submit" variant="primary" class="mx-auto d-block">
                <b-spinner
                  v-if="loading"
                  variant="primary"
                  label="Spinning"
                ></b-spinner
                ><span v-else>ENVIAR</span>
              </b-button>
            </b-form>
          </b-modal>
          <!-- <a :href="proyectoActivo.pdf.url" target="_blank" download>
            <b-img :src="require('@/assets/img/svg/ico-download.svg')"></b-img>
            DESCARGA BOOK DE VENTAS.
          </a> -->
        </div>
      </b-row>
    </b-container>
    <b-overlay v-else :show="true" rounded="sm"></b-overlay>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import router from "../../router";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import CarouselPosts from "@/components/shared/CarouselPosts.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyComponent",
  components: {
    CarouselPosts,
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      form: {
        nombre: "",
        correo: "",
        telefono: "",
        mensaje: "",
      },
      router,
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      categoriaActiva: null,
    };
  },
  methods: {
    ...mapActions({
      getProyectos: "comercializacion/getProyectos",
    }),
    async sendInfo() {
      this.loading = true;
      try {
        if (this.form.mensaje.length === 0) {
          this.$bvToast.toast("Por favor, escribe un mensaje", {
            title: "Error",
            variant: "danger",
            solid: true,
            appendToast: true,
            autoHideDelay: 5000,
          });
          return;
        }
        const res = await fetch(
          `https://us-central1-akko-web.cloudfunctions.net/handleMensajeSoporte`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.form),
          }
        );
        if (res.ok) {
          this.form.nombre = "";
          this.form.correo = "";
          this.form.telefono = "";
          this.form.mensaje = "";

          this.$bvToast.toast("Mensaje enviado", {
            title: "Mensaje enviado",
            autoHideDelay: 3000,
            appendToast: true,
            variant: "success",
          });
        } else {
          this.$bvToast.toast("Error al enviar el mensaje", {
            title: "Error",
            autoHideDelay: 3000,
            appendToast: true,
            variant: "danger",
          });
        }
      } catch (error) {
        console.log(error);
        this.$bvToast.toast("Error al enviar el mensaje", {
          title: "Error",
          autoHideDelay: 3000,
          appendToast: true,
          variant: "danger",
        });
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      proyectos: "comercializacion/proyectos",
      proyectoActivo: "comercializacion/proyectoActivo",
      categorias: "categorias/categoriasComercializacion",
      localeCode: "locale/localeCode",
    }),
    proyectoActivoPdf(){
      if(this.localeCode==='es') return this.proyectoActivo.pdf
      return this.proyectoActivo.pdfIngles
    },
    proyectoActivoNombre(){
      return this.proyectoActivo.nombre?.toUpperCase()
    },
    proyectosFiltrados() {
      if (!this.categoriaActiva || this.categoriaActiva === "todos") {
        return this.proyectos;
      }
      return this.proyectos
        .filter((proyecto) => {
          return proyecto.categoria == this.categoriaActiva;
        })
        .sort((a, b) => (a.posicion > b.posicion ? 1 : -1));
    },
  },
  async mounted() {
    await this.getProyectos();
    this.$store.commit(
      "comercializacion/SET_PROYECTO_ACTIVO",
      this.proyectos[0].id
    );
  },
};
</script>

<style scoped>
.proyectos_upper {
  padding-bottom: 30px;
}
.proyectos_container .proyectos_wrap h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 0.87;
  text-align: left;
  position: relative;
  color: var(--line);
}
.proyectos_container .proyectos_wrap h2 b {
  font-size: 55px;
  font-weight: 900;
}
.proyectos_container .proyectos_wrap hr {
  height: 2px;
  background: var(--primario);
}
.proyectos_container .proyectos_wrap.overlap-top{
  margin-top: 100px;
  margin-bottom: -120px;
}
.proyectos_container .proyectos_wrap h3 {
  position: relative;
  font-size: 28.4px;
  font-weight: bold;
  text-transform: uppercase;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: center;
  color: var(--blanco);
  padding: 19px 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.proyectos_container .proyectos_wrap h3:before {
  z-index: -1;
  opacity: 0.8;
}
.proyectos_container .mas-arrow.big {
  display: block;
  font-size: 16px;
  font-style: italic;
  line-height: 1.2;
  text-align: center;
  color: var(--primario);
  margin-top: 2rem;
}
.proyectos_container .proyectos_wrap.tabs{
  margin-top: -80px;
}
.proyectos_container .proyectos_wrap >>> .tab-content{
  margin-top: -80px;
}
.proyectos_container .proyectos_wrap >>> .nav-tabs {
  border-bottom: none;
  margin-bottom: 140px;
  margin-top: -20px;
}
.proyectos_container .proyectos_wrap >>> .nav-tabs a {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.06px;
  color: var(--texto);
  background: var(--transparente);
  border: none;
  text-transform: uppercase;
  padding: 5px 14px;
  margin: 0 21px;
  border-bottom: 1px solid transparent;
}
.proyectos_container .proyectos_wrap >>> .nav-tabs .nav-item:last-child a {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
}
.proyectos_container .proyectos_wrap >>> .nav-tabs a.active {
  border-bottom-color: var(--primario);
}

.proyecto_wrap >>> .slick-slide {
  margin: 0;
}
.proyecto_wrap >>> .proyecto_carousel {
  padding-top: 140%;
  position: relative;
}
.proyecto_wrap >>> .proyecto_carousel:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  background: var(--overlay);
  z-index: 2;
  mix-blend-mode: multiply;
  background-blend-mode: multiply;
}
.proyecto_wrap >>> .proyecto_carousel .proyecto_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  object-fit: cover;
}
.proyecto_wrap >>> .proyecto_carousel .detalle_wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 28px;
  z-index: 2;
}
.proyecto_wrap >>> .proyecto_carousel .detalle_wrap h5 {
  font-size: 20.1px;
  font-weight: normal;
  line-height: 0.89;
  text-align: left;
  color: var(--blanco);
  width: 50%;
}
.proyecto_wrap >>> .proyecto_carousel .detalle_wrap hr {
  border-top-color: var(--blanco);
}
.proyecto_wrap >>> .proyecto_carousel .detalle_wrap a {
  font-size: 10.6px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.2;
  text-align: left;
  color: var(--blanco);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.proyecto_wrap >>> .proyecto_carousel .detalle_wrap a img {
  filter: brightness(0) invert(1);
  margin-left: 10px;
}
body .proyectosD_container {
  padding-top: 70px;
}
.proyectosD_container .proyectoD_desc {
  padding: 55px 45px;
  background: var(--gris);
  height: 100%;
  overflow: hidden;
}
.proyectosD_container h3 {
  font-size: 19px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: -0.08px;
  text-align: left;
  color: var(--secundario);
  margin-bottom: 26px;
}
.proyectosD_container p {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--texto);
  padding: 0 10px;
}
.proyectosD_container .proyectoD_wrap img {
  height: 100%;
  object-fit: cover;
}
.proyectosD_container a {
  font-size: 17px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.07px;
  color: var(--secundario);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.proyectosD_container a img {
  margin-right: 10px;
}
.b-modal_book{
  background: transparent;
  color: var(--primario);
  border: none;
  font-weight: bold;
}
.b-modal_book:hover,
.b-modal_book:focus{
  text-decoration: underline;
}
@media (max-width: 992px) {
  .proyectos_container .proyectos_wrap h2 {
    padding-top: 30px;
    text-align: center;
  }
  body .proyectosD_container.full {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 576px) {
  .proyectosD_container a {
    flex-direction: column;
    color: var(--dark-gray);
  }
  .proyectos_container .proyectos_wrap h2 {
    font-size: 20px;
    line-height: 1.1;
  }
  .proyectos_container .proyectos_wrap h2 b {
    font-size: 32px;
    line-height: 1.1;
  }
  body .proyectosD_container.full {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
  .proyectos_container .proyectos_wrap.overlap-top{
    margin-top: 0;
    margin-bottom: 0;
  }
  .proyectos_container .proyectos_wrap.tabs{
    margin-top: 0;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs{
    margin-top: 20px;
    margin-bottom: 110px;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs {
    justify-content: center !important;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs a {
    padding: 5px 20px !important;
    margin: 10px 0;
  }
}
</style>
